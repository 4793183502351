
import React from "react"
import { Container } from "react-bootstrap"




const Jobs = () => {

  return (
    <section className="jobheld-job-section" id="jobs">
      <Container>
        <div id="jobheld-widget" data-src="https://bewerbung.jobheld.io/jobs/wohnungseigentum/" data-newtab="true"></div>
      </Container>
    </section>
  )
}

export default Jobs
